import { Box, Button, HStack, Link, Text, VStack } from "@chakra-ui/react";
import * as amplitude from "@amplitude/analytics-browser";
import { navigate } from 'vike/client/router'
import T from "src/renderer/local-text";
import { motion } from "framer-motion";
interface CtaProps {
  size: string;
  withDemo: boolean;
}

const Cta = ({ size = "md", withDemo = false, bg = undefined, color = undefined, children }: CtaProps) => {
  const buttonAttrs = {
    props: {
      size,
      // px: 8,
      colorScheme: "brand",
      bg,
      color,
    },
    content: "Get started",
    contentRequestDemo: children ?? "Request a demo",
    subtitle: "14 days trial, no credit card required!"
  }
  const { props: { ...props } } = buttonAttrs;



  return (
    <Box>
      <Link href='https://calendly.com/plurally/intro' isExternal>
        <Button
          {...props}
        // leftIcon={<FaRocket />}
        >
          <T>
            {buttonAttrs.contentRequestDemo}
          </T>
        </Button>
      </Link >
    </Box>
  )

  const nav = () => {
    amplitude.track("landing_cta_click", buttonAttrs);
    navigate("/auth");
  }


  return (
    <VStack spacing={1}>
      <HStack spacing={4}>
        <Button
          onClick={nav}
          {...props}
        // leftIcon={<FaRocket />}
        >
          {buttonAttrs.content}
        </Button>
        {withDemo && <>
          <Text>
            <T>
              or
            </T>
          </Text>
          <Button
            onClick={nav}
            {...props}
            variant='outline'
          // leftIcon={<FaRocket />}
          >
            {buttonAttrs.contentRequestDemo}
          </Button>
        </>}
      </HStack>
      <Text fontWeight="bold" fontSize="lg" mt="2">
        {buttonAttrs.subtitle}
      </Text>
    </VStack>
  );
};

export default Cta;
