import { Container, Heading, VStack, Text } from "@chakra-ui/react";


const LandingContainer = ({ title, subtitle, desc, children, maxW, ...props }) => {
  return (
    <Container
      maxW={maxW || "7xl"}
      px={{ base: 4, md: 8, lg: 16 }}
      py={{ base: 16, lg: 32 }}
      // mb={12}
      // pt={8}
      scrollMarginTop="100px"
      {...props}
    >
      <VStack w='full' align="stretch" spacing={8} >
        <VStack w='full' spacing={0}>
          {/* <Heading size='md' as='h4' color='brand.600'>{subtitle}</Heading> */}
          <Heading
            align='center'
            as='h2'
            mb={{ base: 0, md: 4 }}
            fontSize={{
              base: '3rem',
              md: '3.75rem'
            }} >{title}</Heading>
          {desc && <Text textAlign='center' mt='4'
          >
            {desc}
          </Text>}
        </VStack>
        {children}
      </VStack>
    </Container>
  );
};

export default LandingContainer;
