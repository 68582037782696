import {
  AvatarGroup,
  Avatar,
  Box,
  useDisclosure,
  VStack,
  Tooltip,
  Collapse,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Flex,
  HStack,
  IconButton,
  Text,
  Button,
  FormControl,
  FormLabel,
  Switch,
  Link,
  Code,
  Spinner,
  Center,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  StackDivider,
} from "@chakra-ui/react";
import Markdown from "./markdown";
import { Handle, NodeToolbar, Position, useConnection, useEdges } from "@xyflow/react";

import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { MdLink } from "@react-icons/all-files/md/MdLink";

import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";
import { MdPlayArrow } from "@react-icons/all-files/md/MdPlayArrow";
import { IoUnlink } from "@react-icons/all-files/io5/IoUnlink";

import { MdArrowUpward } from "@react-icons/all-files/md/MdArrowUpward";
import { MdArrowDownward } from "@react-icons/all-files/md/MdArrowDownward";
import { IoMdExpand } from "@react-icons/all-files/io/IoMdExpand";
import { MdAdd } from "@react-icons/all-files/md/MdAdd";

import JSONForm from "src/JSONForm";
import { UserFriendlyBadge, getUserFriendlyName } from "src/nodesFriendly";
import NodeSummary, {
  getFriendlyTitle,
  getFriendlyType,
} from "src/studio/nodeSummary";
import { FlowsApi, NodeType } from "typescript-axios";
import Modal from "./Modal";
import React from "react";
import NodeHoveredContext from "src/node-hovered-context";
import classNames from "classnames";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { WidgetProps } from "@rjsf/utils";
import { blobUrlToFile, dataUriToFile, getReadableApiError, rndColor } from "src/lib/utils";

const HANDLE_STYLES = {
  target: {
    right: -10,
    // top: 15,
    background: "#d9f7fa",
    minWidth: 25,
    minHeight: 25,
    height: 25,
    width: 25,
    border: "2px solid #76c7d1",
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
  source: {
    right: -10,
    // top: 43,
    background: "#ffebe6",
    minWidth: 25,
    minHeight: 25,
    height: 25,
    width: 25,
    border: "2px solid #e0a800",
    borderRadius: 4,
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
  run: {
    right: -10,
    // top: 43,
    background: "white",
    minWidth: 25,
    minHeight: 25,
    height: 25,
    width: 25,
    border: "2px solid #a0a0a0",
    borderRadius: "100%",
    placeItems: "center",
    display: "grid",
    color: "#333",
    zIndex: 2,
  },
};
import { getSchemaUiSchema } from "../appUtils";
import axios, { getAxiosParams } from "src/lib/axios.config";
import { MdContentCopy } from "@react-icons/all-files/md/MdContentCopy";
import AudioRecorder from "./audio-recorder";
import T from "src/renderer/local-text";

const FLOW_API = new FlowsApi(getAxiosParams(), undefined, axios);

const InnerButton = ({ children, ...rest }) => <Button {...rest}
  size="xs"
  variant='outline'
  borderRadius='none'
  colorScheme="black"
  boxShadow='5px 5px 0px 0px'
  m='2'
>
  {children}
</Button>

const ShowRecorder = ({ flowId }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { isOpen, onToggle } = useDisclosure()
  const toast = useToast()

  const onSubmit = (audioUrl) => {
    blobUrlToFile(audioUrl, 'recording').then(recording => {
      toast({
        title: "Submitting recording",
        status: "info",
      })
      setIsLoading(true)
      FLOW_API.micSubmitApiV1FlowsFlowIdMicSubmitPost(flowId,
        recording
      ).then(() => {
        toast.closeAll()
        toast({
          title: "Recording submitted",
          status: "success",
        })
        onToggle()
      }).catch(e => {
        toast.closeAll()
        toast({
          title: "Error submitting recording",
          description: getReadableApiError(e),
          status: "error",
        })
      }).finally(() => {
        setIsLoading(false)
      });
    })
  }


  return <>
    <Modal isOpen={isOpen} onClose={onToggle} header="Record" size='xl'>
      <VStack align='stretch'>
        <Alert status="info" variant="left-accent" >
          <AlertIcon />
          <AlertDescription>
            For the flow to be triggered when the recording is submitted, the flow must be running!
          </AlertDescription>
        </Alert>
        <AudioRecorder onSubmit={onSubmit} disabled={isLoading} />
      </VStack>
    </Modal>
    <InnerButton
      onClick={onToggle}
    >
      Record 🎙️
    </InnerButton>
  </>
}

const SeeCode = ({ flowId }) => {
  const [code, setCode] = React.useState<string | null>();
  const { isOpen, onToggle } = useDisclosure()
  const toast = useToast()

  React.useEffect(() => {
    if (isOpen) {
      FLOW_API.formWidgetCodeApiV1FlowsFlowIdFormCodeGet(flowId).then((response) => setCode(response.data))
    } else {
      setCode(null)
    }
  }, [isOpen])

  const formPageUrl = `${import.meta.env.VITE_PUBLIC_HOST}/form/${flowId}`

  return <>
    <Modal isOpen={isOpen} onClose={onToggle} header="Your form" size='xl'>
      <VStack align='stretch' divider={<StackDivider />} spacing={4}>
        <VStack align='stretch' >
          <Text>
            You can trigger your flow using the form either by visiting visiting the dedicated page or by embedding the form in your website.
          </Text>
          <Alert status="info" variant="left-accent" >
            <AlertIcon />
            <AlertDescription>
              For the flow to be triggered when the form is submitted, the flow must be running!
            </AlertDescription>
          </Alert>
        </VStack>
        <VStack align='stretch' >
          <Text fontWeight='bold'>Form page</Text>
          <Text>
            Bookmark it for easier access or access it from your mobile!
          </Text>
          <HStack>
            <Link href={formPageUrl} isExternal>
              <Button colorScheme="brand" leftIcon={<MdLink />}>
                Visit form page
              </Button>
            </Link>
            <Text>or</Text>
            <Button
              variant='outline'
              colorScheme="brand"
              leftIcon={<MdContentCopy />}
              onClick={() => {
                navigator.clipboard.writeText(formPageUrl)
                toast({
                  title: "Link copied!",
                  status: "success",
                })
              }
              }>
              Copy link
            </Button>
          </HStack>
        </VStack>
        <VStack align='stretch' >
          <Text fontWeight='bold'>Code</Text>
          <Text>
            Include this code in your website to embed the form. The code will stay the same even if you modify the form.
          </Text>
          {code ? <>
            <Code p={4} rounded='10'>
              {code}
            </Code>
            <HStack>
              <Button
                colorScheme="brand"
                leftIcon={<MdContentCopy />}
                onClick={() => {
                  navigator.clipboard.writeText(code)
                  toast({
                    title: "Code copied!",
                    status: "success",
                  })
                }
                }>
                Copy code
              </Button>
            </HStack>
          </> : <Center p={4}><Spinner /></Center>}
        </VStack>
      </VStack>
    </Modal>
    <InnerButton
      onClick={onToggle}
    >
      Show form ✨
    </InnerButton>
  </>
}

const SeeExtension = () => {
  return <InnerButton>
    <Link href={import.meta.env.VITE_PLURALLY_CHROME_EXTENSION_URL} isExternal>
      Install extension 🚀
    </Link>
  </InnerButton>
}

export const createHandle = ({
  key,
  type,
  left,
  width,
  label,
  isRun,
  isRunConnected,
  isRequired,
  under,
}: {
  key: string;
  type: string;
  left: string;
  width?: string;
  label: string;
  isRun: boolean;
  isRunConnected: boolean;
  isRequired?: boolean;
  under?: React.ReactNode;
}) => {
  return (
    <Handle
      type={type}
      position={type === "target" ? Position.Top : Position.Bottom}
      style={{
        ...HANDLE_STYLES[isRun ? "run" : type],
        width,
        left,
      }}
      className={classNames({
        "run-connected": isRun && isRunConnected,
      })}
      id={key}
    >
      <Box pointerEvents="none" position="relative">
        {<Text fontSize="xs">{label}</Text>}
        {type === "target" && isRequired && (
          <Text
            position="absolute"
            top={-1}
            right={-1}
            color="red"
            fontWeight="bold"
            pointerEvents="none"
          >
            *
          </Text>
        )}
      </Box>
      {under}
    </Handle>
  );
};

export const createHandleWithToolTip = ({
  key,
  value,
  type,
  left,
  label,
  isRun,
  isRunConnected,
  isRequired,
}: {
  key: string;
  value: any;
  type: string;
  left: string;
  label: string;
  isRun: boolean;
  isRunConnected: boolean;
  isRequired: boolean;
}) => {
  return (
    <Tooltip
      key={key}
      label={
        <Box>
          <Text>{getFriendlyTitle(value)}</Text>
          <Text fontSize="xs">({getFriendlyType(value)})</Text>
        </Box>
      }
    >
      {createHandle({
        key,
        type,
        left,
        label,
        isRun,
        isRunConnected,
        isRequired,
      })}
    </Tooltip>
  );
};

export const createHandles = (
  schema,
  type,
  handles,
  isRunConnected: boolean = false
) => {
  const { required, properties } = Object.entries(
    schema.properties ?? {}
  ).reduce(
    (acc, [handle, prop]) => {
      if (handles?.includes(handle)) {
        acc.properties[handle] = prop;
        if (schema.required?.includes(handle)) {
          acc.required.push(handle);
        }
      }
      return acc;
    },
    { required: [], properties: {} }
  );
  const totalHandles = Object.keys(properties).length ?? 0;
  const pct = Math.round(100 / (totalHandles + 1));

  return Object.entries(properties).map(([key, value], ix) => {
    const isRun = key === "run";
    return createHandleWithToolTip({
      key,
      value,
      type,
      left: `${pct * (1 + ix)}%`,
      label: `${type === "target" ? "i" : "o"}${1 + ix}`,
      isRun,
      isRunConnected,
      isRequired: required?.includes(key),
    });
  });
};

export const TypeDesc = ({ desc }): { desc: string | null } => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  // find kls::word pattern in desc
  // capture (word)
  const match = desc.match(/kls::\w+/);
  if (match) {
    const klsName = match[0].split("::")[1];
    desc = desc.replace(/kls::\w+/, getUserFriendlyName(klsName));
  }

  return (
    <VStack align="stretch" w="full">
      <Flex justifyContent="space-between" alignItems="center" w="full">
        <Heading as='h4' size="md" justifyContent="center">
          What this block does 💡
        </Heading>
        <IconButton
          aria-label="Toggle Block Type Description"
          size="xs"
          icon={<MdExpandMore />}
          onClick={onToggle}
        />
      </Flex>
      <Box as={Collapse} in={isOpen} minW="100%">
        <Markdown>{desc}</Markdown>
      </Box>
    </VStack>
  );
};

const HelpNodeModal = ({
  isOpen,
  onToggle,
  nodeType,
  outputSchema,
  inputSchema,
  initSchema,
}: {
  nodeType: NodeType;
  inputSchema: any;
  initSchema: any;
  outputSchema: any;
  userFriendlyName: string;
}) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onToggle}>
      <NodeSummary
        envVars={nodeType.env_vars}
        kls={nodeType.kls}
        desc={nodeType.desc}
        initSchema={initSchema}
        inputSchema={inputSchema}
        outputSchema={outputSchema}
      />
    </Modal>
  );
};

const OpenSubflowButton = ({ onGetSubflowClick }) => {
  return (
    <>
      <MenuItem onClick={onGetSubflowClick} icon={<IoMdExpand />}>
        Open subflow
      </MenuItem>
    </>
  );
};

const getFormRelatedData = (updateSchema, node) => {
  if (updateSchema) {
    const schemaUiSchema = getSchemaUiSchema(updateSchema);
    const initialData = {
      base: {},
      advanced: {},
    };
    Object.entries(node).forEach(([key, value]) => {
      if (schemaUiSchema.schema.properties.base.properties[key]) {
        initialData.base[key] = value;
      }
      if (schemaUiSchema.schema.properties.advanced.properties[key]) {
        initialData.advanced[key] = value;
      }
    });
    // for key in (base, advanced):
    for (const key of ["base", "advanced"]) {
      Object.entries(schemaUiSchema.schema.properties[key].properties).forEach(
        ([propKey, propValue]) => {
          if (propValue.type === "string") {
            const prevSchema = schemaUiSchema.uiSchema[key][propKey] || {};
            if (!prevSchema["ui:emptyValue"]) {
              schemaUiSchema.uiSchema[key][propKey] = {
                ...prevSchema,
                "ui:emptyValue": "",
              };
            }
          }
        }
      );
    }

    return {
      initialData,
      ...schemaUiSchema,
    };
  } else {
    return null;
  }
};

const EditNodeModal = ({ updateSchema, node, nodeType, editNode, onToggle, isOpen, deleteNode }) => {
  const [schemaUISchemaInitialData, setSchemaUISchemaInitialData] =
    React.useState(getFormRelatedData(updateSchema, node));

  React.useEffect(() => {
    setSchemaUISchemaInitialData(getFormRelatedData(updateSchema, node));
  }, [node]);

  return <Modal
    closeOnOverlayClick={false}
    closeOnEsc={false}
    size="4xl"
    header={
      <HStack spacing={4} alignItems="center">
        <Text>Edit Block</Text>
        <Button
          size="xs"
          leftIcon={<MdDelete />}
          color="red"
          onClick={() => deleteNode().then(onToggle)}
          variant="outline"
        >
          Delete Block
        </Button>
      </HStack>
    }
    isOpen={isOpen}
    onClose={onToggle}
  >
    <VStack align="stretch">
      <Box border="1px" borderRadius={4} p={2}>
        {nodeType && <TypeDesc desc={nodeType.desc} />}
      </Box>
      {schemaUISchemaInitialData && <JSONForm
        toastOptions={{
          success: {
            title: "Block updated",
            description: "If your flow was running you must stop and start it again for the changes to take effect",
          },
          error: {
            title: "Error updating block",
          },
        }}
        schema={schemaUISchemaInitialData.schema}
        initialData={schemaUISchemaInitialData.initialData}
        onSubmit={(formData) =>
          editNode({ ...formData.base, ...formData.advanced }).then(onToggle)
        }
        uiSchema={schemaUISchemaInitialData.uiSchema}
      />}
    </VStack>
  </Modal>
}


interface NodeProps {
  nodeType: NodeType;
  inputSchema: any;
  outputSchema: any;
}

const ActionMenu = ({ children }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const ref = React.useRef<NodeJS.Timeout>();
  // const timeout = 500;
  // const onMouseEnter = () => {
  //   onOpen();
  //   if (ref.current) {
  //     clearTimeout(ref.current);
  //     ref.current = null;
  //   }
  // };

  // const onMouseLeave = () => {
  //   ref.current = setTimeout(() => {
  //     onClose();
  //     ref.current = null;
  //   }, timeout);
  // };

  return (
    <Menu size="xs" >
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        size="xs"
      // onMouseEnter={onMouseEnter}
      // onMouseLeave={onMouseLeave}
      >
        Actions
      </MenuButton>
      <MenuList zIndex="3">{children}</MenuList>
    </Menu>
  );
};

const CheckboxWidget = (props: WidgetProps) => {
  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor={props.id} mb="0">
        {props.label}
      </FormLabel>
      <Switch {...props} />
    </FormControl>
  );
};

const EditHandles = ({ type, schema, onEdit, initialValues }) => {
  const edges = useEdges();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isInput = type === "input";

  const initialData = initialValues.reduce((acc, handle) => {
    acc[handle] = true;
    return acc;
  }, {});

  const uiSchema = React.useMemo(
    () =>
      edges.reduce((acc, edge) => {
        if (isInput) {
          acc[edge.targetHandle] = { "ui:disabled": true };
        } else {
          acc[edge.sourceHandle] = { "ui:disabled": true };
        }
        return acc;
      }, {}),
    [edges]
  );

  const formSchema = React.useMemo(() => {
    const properties = Object.entries(schema.properties).reduce(
      (acc, [handle, prop]) => {
        acc[handle] = {
          type: "boolean",
          title: prop.title,
        };
        return acc;
      },
      {}
    );

    return {
      type: "object",
      properties,
    };
  }, [schema]);

  return (
    <>
      <Modal
        header={`Edit ${isInput ? "inputs" : "outputs"}`}
        isOpen={isOpen}
        onClose={onClose}
      >
        <JSONForm
          schema={formSchema}
          // widgets={{ CheckboxWidget }}
          uiSchema={uiSchema}
          initialData={initialData}
          onSubmit={(formData) => {
            const handles = Object.entries(formData).reduce(
              (acc, [key, value]) => {
                if (value) {
                  acc.push(key);
                }
                return acc;
              },
              []
            );
            const key = isInput ? "tgt_handles" : "src_handles";
            return onEdit({ [key]: handles }).then(onClose);
          }}
        />
      </Modal>
      {isInput ? (
        <MenuItem icon={<MdArrowUpward />} onClick={onOpen}>
          Edit Inputs
        </MenuItem>
      ) : (
        <MenuItem icon={<MdArrowDownward />} onClick={onOpen}>
          Edit Outputs
        </MenuItem>
      )}
    </>
  );
};

const Node = ({
  flowId,
  nodeId,
  nodeType,
  inputSchema,
  outputSchema,
  updateSchema,
  editNode,
  deleteNode,
  onGetSubflowClick,
  ungroup,
  data,
  isDemo,
  overrides,
  onIconClick,
  defaultIsExpanded,
  style,
}): NodeProps => {
  const connection = useConnection();

  const { nodeHovered } = React.useContext(NodeHoveredContext);
  const { kls, desc, icon, color } = nodeType;
  const isTrigger = data.is_trigger;
  let bgColor = "white";
  let borderColor = color;
  const isSubflow = kls === "Subflow";
  if (isSubflow) {
    // swap color
    bgColor = rndColor(nodeId);
    borderColor = "brand.500";
  }
  const canAddTargetHandle = inputSchema["can-add-fields"]
  const canAddSourceHandle = outputSchema["can-add-fields"]

  let {
    isOpen: isExpanded,
    onOpen: onExpand,
    onClose: onCollapse,
  } = useDisclosure({
    defaultIsOpen: defaultIsExpanded, //kls !== "FormatText",
  });

  if (overrides?.isExpanded === false) {
    isExpanded = false;
  }

  const {
    onToggle: onToggleEditNodeModal,
    isOpen: isEditNodeModalOpen,
  } = useDisclosure();

  const {
    onToggle: onToggleHelpNodeModal,
    isOpen: isHelpNodeModalOpen,
  } = useDisclosure();

  const isHovered = nodeHovered === nodeId;
  const isTarget =
    connection.inProgress && connection.fromNode.id !== nodeId && isHovered;
  isExpanded = isTarget || isExpanded;

  const { isRunConnected, name } = data;

  const userFriendlyName = getUserFriendlyName(kls);

  const inputProps = Object.entries(inputSchema.properties || {});
  const outputProps = Object.entries(outputSchema.properties || {});

  const tgtHandles = React.useMemo(() => {
    if (isDemo) {
      return inputProps.map(([key]) => (
        <Box visibility="hidden">
          <Handle type="target" position={Position.Top} id={key} />
        </Box>
      ));
    }
    return createHandles(
      inputSchema,
      "target",
      data.tgt_handles,
      isRunConnected
    );
  }, [inputSchema, isRunConnected, data.tgt_handles, isDemo]);

  const srcHandles = React.useMemo(() => {
    if (isDemo) {
      return outputProps.map(([key]) => (
        <Box visibility="hidden" key={key}>
          <Handle type="source" position={Position.Bottom} id={key} />
        </Box>
      ));
    }
    return createHandles(outputSchema, "source", data.src_handles);
  }, [outputSchema, data.src_handles]);

  const centerTitle = React.useCallback(() => {
    let displayText = name;
    // if (data.template) {
    //   displayText = data.template;
    // } else if (data.value) {
    //   displayText = data.value;
    // }

    return (
      <Box maxW="15rem">
        <Text
          fontSize={isSubflow ? "md" : "xs"}
          color='black'
          fontWeight={isSubflow ? "bold" : "normal"}
        // whiteSpace={kls === "Text" ? "nowrap" : "none"}
        // overflow={kls === "Text" ? "hidden" : "visible"}
        // textOverflow={kls === "Text" ? "ellipsis" : "none"}
        >
          <T>{displayText}</T>
        </Text>
      </Box>
    );
    // }, [data.value, data.template, name]);
  }, [name]);

  if (!isExpanded) {

    return (
      <Tooltip label={overrides?.tooltip ?? userFriendlyName}>
        <Box style={style} >

          {inputProps.map(([key]) => (
            <Box visibility="hidden">
              <Handle type="target" position={Position.Top} id={key} />
            </Box>
          ))}
          {!defaultIsExpanded ? (
            <HStack bg='white' rounded='5'
              p={2}
              borderWidth={3}
              boxShadow="xl"
              justify='space-between'
              borderColor={borderColor}>
              <Text>
                {name}
              </Text>
              <AvatarGroup size='xs'>
                {
                  isSubflow ? data.icons.map((icon, ix) => <Avatar key={`avatar-${ix}`} src={`/logos/${icon}.svg`} />) : (icon ? <Avatar src={`/logos/${overrides?.icon ?? icon + '.svg'}`} /> : "")
                }
              </AvatarGroup>
            </HStack>
          ) : (
            <IconButton
              className={overrides.className}
              borderRadius={overrides.borderRadius ?? 4}
              p={2}
              borderWidth={!overrides.className && 4}
              bg="white"
              borderColor={borderColor}
              boxShadow="xl"
              aria-label="Expand Block"
              size={"xs"}
              variant="ghost"
              boxSize={overrides.boxSize ?? 14}
              icon={<Image alt={kls} src={`/logos/${overrides?.icon ?? icon + '.svg'}`} />}
              onClick={onExpand}
            />
          )}
          {outputProps.map(([key]) => (
            <Box visibility="hidden" key={key}>
              <Handle type="source" position={Position.Bottom} id={key} />
            </Box>
          ))}
        </Box>
      </Tooltip>
    );
  }

  let minW = 150;
  if (tgtHandles.length > 0 && tgtHandles.length * 50 > minW) {
    minW = tgtHandles.length * 50;
  }
  if (srcHandles.length > 0 && srcHandles.length * 50 > minW) {
    minW = srcHandles.length * 50;
  }

  const isConnectionInProgress = connection.inProgress && connection.fromNode?.id !== nodeId;
  return (
    <HStack position='relative'>
      {isSubflow && (
        <Box
          position="absolute"
          left="0%"
          transform=" translateX(-50%) rotate(-90deg)"
          px={3}
          py={1}
          bg="brand.500"
          color="white"
          borderRadius="md"
          boxShadow="md"
          zIndex={1}
        >
          <Heading as='h4' size="sm">Subflow</Heading>
        </Box>
      )}
      <Box
        className="default-box"
        position="relative"
        boxShadow="xl"
        minW={minW}
        borderRadius="10"
        borderColor={borderColor}
        borderWidth="4px"
        p={4}
        textAlign="center"
        bg="white"
        style={style}
      >
        {isConnectionInProgress && connection.fromHandle?.type === "source" && canAddTargetHandle && (
          <Handle
            position={Position.Top}
            type='target'
            id={`add-input:${nodeId}`}
            style={{
              "width": "25px",
              "height": "25px",
              "position": "absolute",
              "left": "100%",
              "top": "0%",
              "transform": "translate(-50%, -50%)",
            }}>
            <Box
              pointerEvents="none"
              bg="brand.500"
              color="white"
              p={1}
              borderRadius="full"
              boxShadow="md"
            >
              <MdAdd pointerEvents="none" />
            </Box>
          </Handle>
        )}
        {tgtHandles}
        <Box>
          {icon && !isSubflow && (
            <IconButton
              aria-label="Minimize Block"
              size="xs"
              variant="ghost"
              boxSize={8}
              p={1}
              position="absolute"
              left="0"
              top="50%"
              transform="translate(-50%, -50%)"
              bg="white"
              boxShadow="md"
              colorScheme="brand"
              icon={<Image alt={kls} src={`/logos/${overrides?.icon ?? icon + '.svg'}`} />}
              onClick={onIconClick ?? onCollapse}
            />
          )}
          <VStack align="start" spacing={1} px={2}>
            <Flex w="full" justifyContent="space-between" alignItems="center">
              <HStack>

                {isTrigger && (
                  <Tooltip label="This block will be the one triggering the flow">
                    <Box
                      rounded="full"
                      bg={isSubflow ? "black" : "#e0f6de"}
                      color={isSubflow ? "white" : "blackk"}
                      p="1"
                    >
                      <MdPlayArrow />
                    </Box>
                  </Tooltip>
                )}
                {!isSubflow && <UserFriendlyBadge
                  color={isSubflow ? borderColor : color}
                  kls={kls}
                  textColor={isSubflow ? "white" : "black"}
                  nameOverride={overrides?.kls}
                />}
                {isSubflow && centerTitle()}
              </HStack>
            </Flex>
            {!isSubflow && centerTitle()}
            {["SheetsRead", "SheetsWrite"].includes(kls) && data.sheet_id && (
              <Button
                size="sm"
                as={Link}
                variant="link"
                colorScheme="brand"
                href={`https://docs.google.com/spreadsheets/d/${data.sheet_id}`}
                isExternal
              >
                See data
              </Button>
            )}
            {
              kls === "Form" && <SeeCode flowId={flowId} />
            }
            {
              kls === "Mic" && <ShowRecorder flowId={flowId} />
            }
            {kls === "Chrome" && <SeeExtension />}
            <AvatarGroup
              size="xs"
              max={5}
              as={Flex}
              w="full"
              justifyContent="end"
            >
              {data.icons?.map((icon) => (
                <Avatar
                  bg="white"
                  key={`${nodeId.substr(5)}-${icon}`}
                  src={`/logos/${icon}.svg`}
                />
              ))}
            </AvatarGroup>
          </VStack>
        </Box>
        {srcHandles}
        {isConnectionInProgress && connection.fromHandle?.type === "target" && canAddSourceHandle && (
          <Handle
            position={Position.Bottom}
            type='source'
            id={`add-output:${nodeId}`}
            style={{
              "width": "25px",
              "height": "25px",
              "position": "absolute",
              "left": "100%",
              "bottom": "0%",
              "transform": "translate(-50%, 50%)",
            }}>
            <Box
              pointerEvents="none"
              bg="brand.500"
              color="white"
              p={1}
              borderRadius="full"
              boxShadow="md"
            >
              <MdAdd pointerEvents="none" />
            </Box>
          </Handle>
        )}
      </Box>
      <EditNodeModal
        isOpen={isEditNodeModalOpen}
        onToggle={onToggleEditNodeModal}
        updateSchema={updateSchema}
        deleteNode={deleteNode}
        editNode={editNode}
        nodeType={nodeType}
        node={data}
      />

      <HelpNodeModal
        isOpen={isHelpNodeModalOpen}
        onToggle={onToggleHelpNodeModal}
        nodeType={nodeType}
        inputSchema={inputSchema}
        initSchema={nodeType.init}
        outputSchema={outputSchema}
        userFriendlyName={userFriendlyName}
      />

      <NodeToolbar isVisible={!isDemo && isHovered && !isTarget} position={Position.Right}>
        <ActionMenu>
          {!isSubflow && <>
            <MenuItem icon={<FiHelpCircle />} onClick={onToggleHelpNodeModal}>
              About this block
            </MenuItem>
            <MenuItem icon={<MdEdit />} size="xs" onClick={onToggleEditNodeModal}>
              Edit
            </MenuItem>
          </>
          }
          {isSubflow && (
            <OpenSubflowButton onGetSubflowClick={onGetSubflowClick} />
          )}
          {Object.keys((inputSchema.properties || {})).length > 0 && (
            <EditHandles
              schema={inputSchema}
              type="input"
              onEdit={editNode}
              initialValues={data.tgt_handles}
            />
          )}
          {Object.keys((outputSchema.properties || {})).length > 0 && (
            <EditHandles
              schema={outputSchema}
              type="output"
              onEdit={editNode}
              initialValues={data.src_handles}
            />
          )}
          {isSubflow && (
            <>
              <MenuItem onClick={ungroup} icon={<IoUnlink />}>
                Ungroup
              </MenuItem>
            </>
          )}
          <MenuItem onClick={deleteNode} color="red" icon={<MdDelete />}>
            Delete
          </MenuItem>
        </ActionMenu>
      </NodeToolbar>
    </HStack >
  );
};

export default Node;
