import type { ComponentStyleConfig } from "@chakra-ui/react";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "100",
  },
  sizes: {
    // Define your custom size
    "xl": {
      h: "14", // height
      minW: "12", // minimum width
      fontSize: "lg", // font size
      px: "8", // horizontal padding
    },
    "2xl": {
      h: "16", // height
      minW: "16", // minimum width
      fontSize: "xl", // font size
      px: "8", // horizontal padding
    },
  },
  variants: {
    outline: {
      borderWidth: "3px",
    },
  },
};
