import validator from "@rjsf/validator-ajv8";
import React from "react";
import Form from "@rjsf/chakra-ui";
import { VStack, Heading, SimpleGrid, GridItem, Stack, Button, HStack, Tooltip, useToast } from "@chakra-ui/react";
import { generateUiSchema } from "./lib/utils";
import AudioRecorderWidget from "./components/audio-recorder-widget";
import PoweredBy from "./components/powered-by";
import { transformErrors } from "./lib/form-utils";


const ObjectFieldTemplate = ({ title, properties, uiSchema }) => {
    return (<VStack align='stretch'>
        <Heading as="h4" size="md" mb={2}>
            {/* {title} */}
        </Heading>
        {uiSchema?.['ui:grid'] ? (
            <SimpleGrid columns={12} gap={{ base: 4 }} align='start'>
                {Array.isArray(uiSchema['ui:grid']) ?
                    uiSchema['ui:grid'].map(([uiGridItemName, uiGridItemSpan]) => {
                        let element = properties.find((p => p.name === uiGridItemName))
                        if (element) {
                            return <GridItem key={element.name} colSpan={uiGridItemSpan}>
                                {element.content}
                            </GridItem>
                        }
                        return ""
                    }) : properties.map((element) => (
                        <GridItem key={element.name} colSpan={12}>
                            {element.content}
                        </GridItem>
                    ))}
            </SimpleGrid>) :
            <VStack spacing={4} align="stretch">
                {properties.map((prop) => prop.content)}
            </VStack>
        }
    </VStack>
    );
}

function getTextColor(backgroundColor) {
    // Convert hex color to RGB values
    const hex = backgroundColor.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate luminance
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    // Return 'black' or 'white' based on luminance
    return luminance > 186 ? "black" : "white";
}


const FormBuilt = ({ jsonSchema, onSubmit, isPreview, buttonText, buttonColor, buttonOnSameRow }) => {
    const submitFormRef = React.useRef();
    const [schema, setSchema] = React.useState(null);
    const [formData, setFormData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        const uiSchema = generateUiSchema(jsonSchema)
        uiSchema['ui:options'] = { 'label': false }
        setSchema({ schema: jsonSchema, uiSchema });
    }, [jsonSchema]);

    if (!schema) {
        return ""
    }

    return <Stack
        bg='transparent'
        w='full'
        direction={{ base: 'column', sm: buttonOnSameRow ? 'row' : 'column' }}
        align={{
            base: 'stretch',
            sm: buttonOnSameRow ? 'end' : 'stretch'
        }}
        justify='stretch'
    >
        <Form
            transformErrors={transformErrors}
            // readonly={isPreview}
            tagName={isPreview ? 'div' : undefined}
            schema={schema.schema}
            onChange={({ formData }) => setFormData(formData)}
            formData={formData}
            uiSchema={schema.uiSchema}
            validator={validator}
            onSubmit={(formData, e) => {
                e.preventDefault();
                if (!isPreview) {
                    setIsLoading(true);
                    return onSubmit(formData).finally(() => setIsLoading(false))
                }
            }}
            widgets={{ AudioRecorderWidget }}
            templates={{ ObjectFieldTemplate }}
        >
            <button ref={submitFormRef} type="submit" style={{ display: 'none' }} disabled={isLoading} />
        </Form>
        {/* <HStack justify='end'> */}
            <Button
                onClick={() => {
                    if (isPreview) {
                        onSubmit()
                    } else {
                        submitFormRef.current.click()
                    }
                }}
                isDisabled={isLoading}
                colorScheme="brand"
                // bg={buttonColor}
                // color={getTextColor(buttonColor)}
                mb='1' // to align properly with the form
            >
                {buttonText}
            </Button>
        {/* </HStack> */}
        {/* <PoweredBy /> */}
    </Stack >

}

export default FormBuilt;