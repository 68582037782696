import { Box, Flex, Heading, IconButton, Button, Text } from '@chakra-ui/react';
import { useState, useRef } from 'react';
import { FaMicrophone, FaStop } from 'react-icons/fa';
import PoweredBy from './powered-by';
import T from 'src/renderer/local-text';

interface AudioRecorderProps {
    title: string;
    onSubmit: (audioUrl: string) => void;
    disabled: boolean;
}

export const InnerAudioRecorder = ({ audioUrl, setAudioUrl, disabled }) => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunks = useRef<Array<Blob>>([]);
    const mimeTypeRef = useRef<string | null>(null);

    const startRecording = async () => {
        setAudioUrl('');
        setIsRecording(true);
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
            if (audioChunks.current?.length > 0) {
                const mimeType = audioChunks.current[0].type
                mimeTypeRef.current = mimeType;
                const audioBlob = new Blob(audioChunks.current, { type: mimeType });
                const url = URL.createObjectURL(audioBlob);
                setAudioUrl(url)
                audioChunks.current = [];
            }
        };

        mediaRecorderRef.current.start(1000); // https://community.openai.com/t/whisper-problem-with-audio-mp4-blobs-from-safari/322252
    };

    const stopRecording = () => {
        setIsRecording(false);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    return (
        <Flex direction="column" align="center" justify="center">
            <Box textAlign="center" mb={4}>
                <Text color="gray.800">
                    {/* {isRecording ? 'Recording in progress...' : 'Press the button to start recording'} */}
                    <T>{isRecording ? '_audio_recorder:in_progress' : '_audio_recorder:press_button'}</T>
                </Text>
            </Box>
            <IconButton
                isDisabled={disabled}
                _hover={{ transform: 'scale(1.1)' }}
                onClick={isRecording ? stopRecording : startRecording}
                icon={isRecording ? <FaStop /> : <FaMicrophone />}
                colorScheme={isRecording ? 'red' : 'green'}
                size="lg"
                aria-label="Record"
                isRound
                mb={4}
            />
            {audioUrl && mimeTypeRef.current && (
                <Flex mt={4} p={4} gap={4} direction='column' >
                    <Box>
                        <Text fontWeight='bold'>
                            Preview
                        </Text>
                        <audio controls>
                            <source src={audioUrl} type={mimeTypeRef.current} />
                            Your browser does not support the audio element.
                        </audio>
                    </Box>
                </Flex>
            )}
        </Flex>
    );
}

function AudioRecorder({ title, onSubmit, disabled }: AudioRecorderProps) {
    const [audioUrl, setAudioUrl] = useState<string>('');

    return (
        <Flex direction="column" align="center" justify="center" mt={8} p={6} borderRadius="md" bg="gray.50">
            <Heading as='h4'>
                {title}
            </Heading>
            <InnerAudioRecorder audioUrl={audioUrl} setAudioUrl={setAudioUrl} disabled={disabled} />
            <Button w='full' colorScheme='brand' onClick={() => onSubmit(audioUrl)} isDisabled={disabled || !audioUrl}>
                Submit
            </Button>
            <PoweredBy />
        </Flex>
    );
}

export default AudioRecorder;
