import {
  Box,
  Button,
  useDisclosure,
  Text,
  VStack,
  Heading,
  StackDivider,
  Collapse,
  Flex,
} from "@chakra-ui/react";
import { getUserFriendlyName } from "src/nodesFriendly";
import { TypeDesc } from "src/components/node";
import Markdown from "src/components/markdown";
const typeMapping = {
  string: "Text",
  boolean: "True/False",
  array: "List",
  object: "Object",
};

export const getFriendlyTitle = (paramData) => {
  if (!paramData) {
    return "unknown";
  }
  return paramData.title || paramData.allOf?.[0].title || "unknown";
};

export const getFriendlyType = (paramData) => {
  if (paramData["type-friendly"]) {
    // hack
    return paramData["type-friendly"].toLocaleUpperCase();
  }
  let typeStr = "";
  if (paramData.type === "object") {
    typeStr = paramData.title || "Object";
  } else if (paramData.type) {
    if (paramData.format === "date-time") {
      typeStr = "Datetime";
    } else {
      typeStr = typeMapping[paramData.type] || paramData.type;
    }
  } else {
    typeStr = (paramData.allOf || paramData.anyOf || paramData.oneOf)
      ?.map((v) => {
        if (v.type === "object") {
          return typeMapping[v.title] || v.title;
        } else {
          return typeMapping[v.type] || v.type;
        }
      })
      .join(" | ");
  }

  return (typeStr || "").toLocaleUpperCase();
};

const ParameterCard = ({ paramData, isRequired }) => {
  return (
    <VStack align="stretch" direction="column">
      <Flex justify="space-between" align="center">
        <Box>
          <Heading as="h3" size="sm">
            {paramData.title}
            {isRequired && (
              <Text as="span" color="red.500" ml={1}>
                *
              </Text>
            )}
          </Heading>
          {paramData.default && (
            <Text fontSize="xs" fontStyle="italic">
              Default: {paramData['default-show'] ?? paramData.default.toString()}
            </Text>
          )}
        </Box>
        <Text fontWeight="bold">{getFriendlyType(paramData)}</Text>
      </Flex>
      {paramData['markdown-desc'] && <Markdown>{paramData['markdown-desc']}</Markdown>}
      {!paramData['markdown-desc'] && paramData.description && <Text>{paramData.description}</Text>}
      {paramData['markdown-examples'] && <VStack w='full' align='stretch'><Text fontWeight='bold' mb='2'>Examples:</Text><Markdown>{paramData['markdown-examples']}</Markdown></VStack>}
      {!paramData['markdown-examples'] && paramData.examples?.length > 0 && (
        <VStack w='full' px={2} align='stretch'>
          <Text fontWeight='bold'>Examples:</Text>
          {paramData.examples?.map((example) => (
            <Box
              px={2}
              bg="gray.50"
              borderWidth={1}
              borderRadius="md"
              key={example}
            >
              <Text>{JSON.stringify(example, null, 2)}</Text>
            </Box>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

const getSchemaFormatted = (schema, type) => {
  const { properties, required, description } = schema;
  const fontSize = "sm";
  if (!properties || Object.entries(properties).length == 0) {
    return (
      <Box ps={4} fontSize={fontSize} p={2}>
        {description && <Markdown children={description} />}
        <Text>{type === "output" ? "No outputs" : "No Inputs"}</Text>
      </Box>
    );
  }
  return (
    <Box w='full'>
      {/* {description && <Markdown mb={2} children={description} />} */}
      <Box

        ps={4}
        fontSize={fontSize}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        p={2}
      >
        {/* <Heading as="h2" size="lg" mb={4}>
          {title} Parameters
        </Heading> */}
        <VStack
          spacing={4}
          align="stretch"
          divider={<StackDivider />}

        >
          {Object.entries(properties)
            .filter(([k, v]) => {
              if (type === "init") {
                return (
                  k !== "pos_x" &&
                  k !== "pos_y" &&
                  k !== "src_handles" &&
                  k !== "tgt_handles"
                );
              } else {
                return true;
              }
            })
            .filter(([k, v]) => {
              if (v.format === "hidden") {
                return false;
              }
              return true;
            })
            .map(([key, value]) => (
              <ParameterCard
                key={key}
                paramData={value}
                isRequired={required?.includes(key)}
              />
            ))}
        </VStack>
      </Box>
    </Box>
  );
};

const NodeSummary = ({
  kls,
  envVars,
  desc,
  initSchema,
  inputSchema,
  outputSchema,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <VStack alignItems="start" align="stretch" spacing={4}>
      <VStack alignItems="start" align="stretch" >
        <Heading size="lg" as='h3'>{getUserFriendlyName(kls)}</Heading>
        <TypeDesc desc={desc} />
      </VStack>
      <VStack alignItems="start" align="stretch" w='full'>
        <Heading size="md"  as='h3'>Inputs</Heading>
        {getSchemaFormatted(inputSchema, "target")}
      </VStack>
      <VStack alignItems="start" align="stretch" w='full'>
        <Heading size="md" as='h3'>Outputs</Heading>
        {getSchemaFormatted(outputSchema, "source")}
      </VStack>
      {initSchema && (
        <VStack alignItems="start" align="stretch" w='full'>
          <Flex justifyContent="end">
            <Button size="xs" variant="ghost" onClick={onToggle}>
              Show static input
            </Button>
          </Flex>
          <Box as={Collapse} in={isOpen} w='full'>
            <VStack alignItems="start" align="stretch" w='full'>
              <Heading size="md" as='h3'>Static inputs</Heading>
              <Text fontSize="sm" color="gray.500" fontStyle="italic">
                These inputs do not change during execution.
              </Text>
              {getSchemaFormatted(initSchema, "init")}
              {envVars && (
                <>
                  <Heading size="md">API Keys</Heading>
                  <Markdown>{envVars}</Markdown>
                </>
              )}
            </VStack>
          </Box>
        </VStack>
      )}
    </VStack>
  );
};

export default NodeSummary;
