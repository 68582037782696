import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import type { CreateToastFnReturn } from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  Collapse,
  Flex,
  AlertTitle,
  Box,
  Heading,
  Link,
  Tooltip,
  useDisclosure,
  Button,
  Spinner,
  useToast,
  Text,
  IconButton,
  FormLabel,
  FormHelperText,
  FormControl,
  Input,
  HStack,
  SimpleGrid,
  Select,
  GridItem,
  VStack,
  Icon,
  Center,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import type { UiSchema } from "@rjsf/chakra-ui";
import Form from "@rjsf/chakra-ui";
import type { RJSFSchema, RegistryWidgetsType } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import dayjs from "dayjs";
import React from "react";
import { getReadableApiError } from "src/lib/utils";
import { createHandle } from "./components/node";
import Markdown from "./components/markdown";
import CronWidget from "src/cron";
import { MdArrowDownward } from "@react-icons/all-files/md/MdArrowDownward";
import { MdArrowUpward } from "@react-icons/all-files/md/MdArrowUpward";
import { FiHelpCircle } from "react-icons/fi";
import FormPreview from "./form-preview";
import RJSFUiGrid from "./components/rjsf-ui-grid";
import { transformErrors } from "./lib/form-utils";

interface MessageOption {
  title?: string | React.ReactNode | null;
  description?: string | React.ReactNode | null;
}

interface ToastOptions {
  loading: MessageOption;
  success: MessageOption;
  error: MessageOption;
}

const defaultToastOptions: ToastOptions = {
  loading: { title: "Loading" },
  success: { title: "Success" },
  error: { title: "Something went wrong" },
};



function FieldHelpTemplate({ help }) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  if (!help) {
    return null;
  }
  return (
    <>
      <Box as={Collapse} in={isOpen} animateOpacity w="full">
        <FormHelperText>
          <Markdown>{help}</Markdown>
        </FormHelperText>
      </Box>
      <Flex justifyContent="end" w="full">
        <Button variant="link" onClick={onToggle} size="sm">
          {isOpen ? "Hide" : "Show"} help
        </Button>
      </Flex>
    </>
  );
  return <Markdown>{help}</Markdown>;
}


const SelectWidget = ({
  id,
  value,
  required,
  label,
  onChange,
  schema,
  placeholder,
  uiSchema,
}) => {
  const enumOptions = schema.enum?.map((value: any) => ({ value, label: value }));
  if (!enumOptions)
    return ""
  const labels = (uiSchema?.labels || {})
  return (
    <FormControl id={id}>
      <FormLabel htmlFor={id}>
        {label}
        {required && <Text as="span" color='red.500' ms='1'>*</Text>}
      </FormLabel>
      <Select
        id={id}
        placeholder={placeholder}
        isRequired={required}
        value={value || ""}
        onChange={(event) => onChange(event.target.value)}
      >
        {enumOptions.map((option: any, i: number) => (
          <option key={i} value={option.value}>
            {labels[option.label] ?? option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};


const ArrayFieldTemplate = ({
  id,
  title,
  required,
  items,
  canAdd,
  onAddClick,
  schema,
  uiSchema: { tooltip_label: tooltipLabel_ } }) => {
  const { is_output: isOutput, is_input: isInput, name_singular: nameSingular } = schema;
  const key = title.toLowerCase();
  let tooltipLabel = ""
  if (isInput) {
    tooltipLabel = "Adding items to this list will define the inputs of this block."
  } else if (isOutput) {
    tooltipLabel = "Adding items to this list will define the outputs of this block."
  } else if (tooltipLabel_) {
    tooltipLabel = tooltipLabel_
  }

  return (
    <FormControl id={id}>
      <Tooltip label={tooltipLabel} isDisabled={!tooltipLabel}>
        <FormLabel htmlFor={id}>
          {title}
          {required && <Text as="span" color='red.500' ms='1'>*</Text>}
          <Icon ms='2' as={FiHelpCircle} visibility={tooltipLabel ? 'visible' : 'hidden'} />
        </FormLabel>
      </Tooltip>
      {!items?.length && (
        <Text>
          Click the button below to add some{" "}
          <Text as="span" fontStyle="italic">
            {key}
          </Text>
          .
        </Text>
      )}
      {items && (
        <>
          {(isOutput || isInput) && items.some(element => element?.children?.props?.formData?.free) && (
            <Box w="full" h="4" position="relative" mt={4}>
              {items.map((element, index) => {
                const label = element.children.props.formData.free;
                if (!label || !(typeof label === "string")) {
                  return "";
                }
                const type = isOutput ? "source" : "target";
                return (
                  <Tooltip label={label} key={element.key}>
                    {createHandle({
                      type,
                      left: `${((index + 1) / (items.length + 1)) * 100}%`,
                      label: `${isOutput ? "o" : "i"}${index + 1}`,
                      isRun: false,
                      isRunConnected: false,
                      isRequired: false,
                    })}
                  </Tooltip>
                );
              })}
            </Box>
          )}
          <VStack spacing={4} mt={2} align='stretch'>
            {items.map((element, index) => {
              return (
                <Box p={4} key={element.key} borderWidth='1px' rounded='10'>
                  <HStack spacing={2} align="center">
                    <Text fontWeight='bold'>{`${nameSingular} ${index + 1}`}</Text>
                    <IconButton
                      p={0}
                      variant="link"
                      colorScheme="red"
                      aria-label="Remove item"
                      icon={<DeleteIcon />}
                      onClick={element.onDropIndexClick(element.index)}
                      ms={2}
                    />
                    {element.hasMoveUp > 0 && (
                      <IconButton
                        p={0}
                        variant="link"
                        colorScheme="blue"
                        aria-label="Move up"
                        icon={<MdArrowUpward />}
                        onClick={element.onReorderClick(element.index, element.index - 1)}
                        ms={2}
                      />
                    )}
                    {element.hasMoveDown && (
                      <IconButton
                        p={0}
                        variant="link"
                        colorScheme="blue"
                        aria-label="Move down"
                        icon={<MdArrowDownward />}
                        onClick={element.onReorderClick(element.index, element.index + 1)}
                        ms={2}
                      />
                    )}
                  </HStack>
                  {element.children}
                </Box>
              );
            })}
          </VStack>
        </>
      )}
      {canAdd && (
        <Button
          mt='4'
          size="sm"
          colorScheme='brand'
          variant='outline'
          leftIcon={<AddIcon />}
          aria-label={`Add ${key}`}
          onClick={onAddClick}
        >
          Add {key}
        </Button>
      )}
      {/* <FormHelperText>{schema.description}</FormHelperText> */}
    </FormControl>
  );
};

interface JSONFormProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: RJSFSchema;
  onSubmit: (formData: T, schemaSubmitted: RJSFSchema) => Promise<unknown>;
  uiSchema: UiSchema;
  toastOptions?: Partial<ToastOptions>;
  initialData?: Partial<T>;
  widgets?: RegistryWidgetsType;
  onChange?: (formData?: T) => void;
  children?: React.ReactNode;
  readonly?: boolean;
  addPlaceholders?: boolean;
  onCreateSheet?: () => Promise<string>;
}

const preprocessUiSchema = (schema: RJSFSchema, uiSchema: UiSchema) => {
  const newUiSchema = { ...uiSchema };
  const properties = schema.properties || {};
  for (const key in properties) {
    if (properties[key].examples) {
      newUiSchema[key] = {
        ...newUiSchema[key],
        "ui:placeholder": properties[key].examples?.[0],
        "ui:help": properties[key].help,
      };
    }
  }
  return newUiSchema;
};

function mergeWithDefaultToastOptions(
  options: Partial<ToastOptions>
): ToastOptions {
  return {
    loading: {
      ...defaultToastOptions.loading,
      ...options.loading,
    },
    success: {
      ...defaultToastOptions.success,
      ...options.success,
    },
    error: {
      ...defaultToastOptions.error,
      ...options.error,
    },
  };
}

export const toastPromise = (
  promise: Promise<unknown>,
  toast: CreateToastFnReturn,
  opts?: Partial<ToastOptions>
) => {
  toast.closeAll();
  return toast.promise(
    promise.catch((e) => {
      console.error(e);
      throw e;
    }),
    mergeWithDefaultToastOptions(opts || defaultToastOptions)
  );
};

interface OnSubmitArgs<T> {
  formData?: T | undefined;
  schema: RJSFSchema;
}

const allDatesShouldBeDefaultedToNow = (schema: RJSFSchema) => {
  const newSchema = { ...schema };
  Object.entries(schema.properties || {}).forEach(([key, property]) => {
    if (property.properties) {
      newSchema.properties[key] = allDatesShouldBeDefaultedToNow(property);
    } else {
      if (property.format === "date-time") {
        if (
          newSchema.required &&
          newSchema.required.includes(key) &&
          !property.default
        ) {
          newSchema.properties[key].default = dayjs()
            .startOf("minute")
            .toISOString();
        }
      }
    }
  });
  return newSchema;
};



function FormPreviewWidget({ formData }) {
  const { isOpen, onToggle } = useDisclosure()
  const payload = { ...formData.base, ...formData.advanced }
  if (!payload.form) {
    // first render it's empty
    return ""
  }
  return <VStack align='stretch'>
    <Box borderWidth='4px' p={4} borderRadius={10} borderStyle='dashed'>
      <Flex justify='space-between'>
        <Heading size='md' as='h4'>
          Preview
        </Heading>
        <Button variant='link' onClick={onToggle}>{isOpen ? 'Hide preview' : 'Show preview'}</Button>
      </Flex>
      <Box as={Collapse} in={isOpen}>
        <FormPreview isPreview={true} onSubmit={() => alert("This would trigger your flow!")} initData={payload} />
      </Box>
    </Box>
  </VStack>
}



const ObjectFieldTemplate = ({ title, properties, uiSchema }) => {
  return (<VStack align='stretch'>
    <Heading as="h4" size="md" mb={2}>
      {title}
    </Heading>
    <RJSFUiGrid properties={properties} uiSchema={uiSchema} />
  </VStack>
  );
}

const JSONForm = <T,>({
  schema,
  uiSchema,
  onSubmit,
  toastOptions,
  initialData,
  widgets,
  onChange,
  readonly,
  children,
  addPlaceholders,
  onCreateSheet,
}: JSONFormProps<T>) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const toast = useToast();
  const [apiError, setApiError] = React.useState<string | null>(null);
  const [formData, setFormData] = React.useState<T | undefined>(
    initialData || {}
  );

  const SheetIdWidgetInner = ({
    id,
    value,
    required,
    disabled,
    readonly,
    label,
    onChange,
  }) => {
    const [isDisabledFromCreateSheet, setIsDisabledFromCreateSheet] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const toast = useToast();
    return (
      <FormControl id={id}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <SimpleGrid columns={3} spacing={2} >
          <GridItem colSpan={2}>
            <Input
              id={id}
              value={value || ""}
              isRequired={required}
              isDisabled={disabled || readonly || isDisabledFromCreateSheet}
              onChange={(event) => onChange(event.target.value)}
            />
          </GridItem>
          <GridItem colSpan={1}>
            {value && <HStack
              w="full"
              spacing="2"
              alignItems="center"
              justifyContent="center"
              h='full'
            >
              <Button as={Link} href={`https://docs.google.com/spreadsheets/d/${value}`} isExternal variant='link' colorScheme="brand">
                See sheet
              </Button>
              {
                onCreateSheet &&
                <IconButton
                  aria-label="Clear sheet field"
                  colorScheme="red"
                  icon={<MdClose />}
                  variant='ghost'
                  onClick={() => {
                    setIsDisabledFromCreateSheet(false);
                    onChange("")
                  }}
                />}
            </HStack>
            }
            {onCreateSheet && !value && (
              <Box as={Collapse} in={!value} animateOpacity>
                <HStack
                  w="full"
                  spacing="2"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="full"
                    isDisabled={isLoading || isDisabledFromCreateSheet}
                    colorScheme="brand"
                    variant='ghost'
                    onClick={() => {
                      setIsLoading(true);
                      toast.closeAll();
                      toast({
                        status: "info",
                        title: "Creating sheet...",
                      })
                      onCreateSheet()
                        .then(sheetId => {
                          toast.closeAll();
                          if (sheetId) {
                            toast({
                              status: "success",
                              title: "Sheet created",
                              description: "Sheet created successfully",
                            })
                            setIsDisabledFromCreateSheet(true);
                          } else {
                            toast({
                              status: "info",
                              title: "Sheet creation requires authorization",
                            })
                            setIsDisabledFromCreateSheet(false);
                          }
                          onChange(sheetId);
                        })
                        .finally(() => setIsLoading(false))
                    }}
                  >
                    Create sheet
                  </Button>
                </HStack>
              </Box>
            )
            }
          </GridItem>
        </SimpleGrid>
        {onCreateSheet && (
          <FormHelperText>
            If you do not have a Google Sheet for this flow yet, click <b>Create sheet</b> and we'll create one for you (this will require you to connect your Google Sheets account).
          </FormHelperText>
        )}
      </FormControl >
    );
  };

  const SheetIdWidget = React.useMemo(() => {
    return SheetIdWidgetInner;
  }, [onCreateSheet]);

  const handleSubmit = ({
    formData,
    schema: submittedSchema,
  }: OnSubmitArgs<T>) => {
    if (!formData) {
      throw new Error("formData is undefined");
    }
    if (isLoading) return
    setApiError(null);
    setIsLoading(true);
    return toastPromise(
      onSubmit(formData, submittedSchema)
        .catch((e) => {
          setApiError(getReadableApiError(e));
          if (e.status !== 422) {
            console.error(e);
          }
          throw e;
        })
        .finally(() => {
          setTimeout(() => {
            // Prevents the form from being submitted twice
            setIsLoading(false);
          }, 1000);
        }),
      toast,
      toastOptions
    );
  };

  const schemaWithDefaults = React.useMemo(() => {
    return allDatesShouldBeDefaultedToNow(schema);
  }, [schema]);

  const innerOnChange = (
    { formData }: OnSubmitArgs<T>,
    id: string | undefined
  ) => {
    setFormData(formData);
    if (onChange) {
      if (id) {
        onChange(formData);
      }
    }
  };

  const uiSchemaProcessed = React.useMemo(() => {
    return (addPlaceholders ? uiSchema : preprocessUiSchema(schema, uiSchema)) as UiSchema;
  }, [addPlaceholders, schema, uiSchema]);

  if (typeof window === 'undefined') {
    return <Center><Spinner /></Center>
  }

  return (
    <>
      {uiSchemaProcessed.preview === 'FormPreview' && <FormPreviewWidget formData={formData} />}
      <Form
        transformErrors={transformErrors}
        readonly={readonly}
        disabled={isLoading}
        onSubmit={handleSubmit}
        schema={schemaWithDefaults}
        uiSchema={uiSchemaProcessed}
        validator={validator}
        formData={formData}
        widgets={{ ...(widgets || {}), SheetIdWidget, CronWidget, SelectWidget }}
        onChange={innerOnChange}
        templates={{
          FieldHelpTemplate,
          ArrayFieldTemplate,
          ObjectFieldTemplate,
        }}
      >

        {children}
        {
          apiError && (
            <Alert variant='left-accent' status="error" mb={4}>
              <AlertIcon />
              <AlertTitle>{apiError}</AlertTitle>
            </Alert>
          )
        }
        {
          !readonly && (
            <Box mt='2' textAlign="end">
              <Button
                colorScheme="brand"
                type="submit"
                spinner={<Spinner />}
                isDisabled={isLoading}
              >
                Submit
              </Button>
            </Box>
          )
        }
      </Form >
    </>
  );
};

export default JSONForm;
