import { extendTheme } from '@chakra-ui/react';

import { colors } from './colors';
import { components } from './components';
import { config } from './config';
import { fonts } from './fonts';
import { styles } from './styles';
const customTheme = extendTheme({
  textStyles: {
    heading: {
      lineHeight: "10",
    },
  },
  fonts,
  colors,
  config,
  components,
  styles,
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fontSizes: {
    xs: "0.75rem",   // Extra small
    sm: "1rem",      // Small
    md: "1.4rem",    // Medium
    lg: "1.5rem",   // Large
    xl: "2rem",      // Extra Large
    "2xl": "2.5rem", // 2x Large
    "3xl": "3rem",   // 3x Large
    "4xl": "4rem",   // 4x Large
    "5xl": "5rem",   // 5x Large
    "6xl": "6rem",   // 6x Large
  }
});

export default customTheme;
