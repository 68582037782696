import React, { useState } from "react";
import {
    Box,
    Text,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Button,
    Flex,
    Heading,
    Divider,
    VStack,
    FormLabel,
    SimpleGrid,
    GridItem,
    HStack,
    Link,
} from "@chakra-ui/react";
import { FaCaretRight } from "@react-icons/all-files/fa/FaCaretRight";
import { motion } from "framer-motion";
import T from "src/renderer/local-text";
import Cta from "src/home/Cta";
import { usePageContext } from "vike-react/usePageContext";
import translate from "src/locales/translate";
import dayjs from "dayjs";

const SliderEntry = ({
    title,
    value,
    setValue,
    min,
    max,
    step,
    name,
    transform = (v) => v
}) => {

    return (<Box>
        <Flex justify='space-between' mb={2} fontWeight="bold">
            <FormLabel fontSize='sm' htmlFor={name}>
                <T>{title}</T>
            </FormLabel>
            <Box as="span" display='inline-block' whiteSpace='nowrap'>
                {transform(value)}
            </Box>
        </Flex>
        <Slider
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={setValue}
        >
            <SliderTrack bg='brand.100'>
                <SliderFilledTrack bg='brand.500' />
            </SliderTrack>
            <SliderThumb boxSize={6} />
        </Slider>
    </Box>
    )
}

const formatCurrency = (value, locale) => {
    let ccy = "USD"
    if (locale == 'fr') {
        locale = 'fr-FR'
        ccy = 'EUR'
    } else {
        locale = 'en-US'
    }
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: ccy,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value)
}

const getSavings = (numSales, pricePerHour, hoursEntering, locale) => {
    return formatCurrency((numSales || 0) * (pricePerHour || 0) * (hoursEntering || 0) * 20 * 0.9, locale)
}

const CostSimulator = () => {
    const [numSales, setNumSales] = React.useState(50);
    const [hourlySalary, setHourlySalary] = React.useState<number>(35);
    const [hoursEntering, setHoursEntering] = React.useState<number>(1.5);
    const { locale } = usePageContext()
    const savings = getSavings(numSales, hourlySalary, hoursEntering, locale)
    const [showCta, setShowCta] = React.useState(false);

    return (
        <VStack color='brand.800' spacing={4} align='stretch' px={4}>
            <Heading size="md"
                as='h3'>
                <T>_lp:simulator:costs:title</T>
            </Heading>
            <SimpleGrid
                // bg='white'
                justify='center'
                columns={{ base: 1, md: 2 }}
                bg='#e4fafb'
                color='#10122A'
                p={4}
                borderRadius="md"
                textAlign="center"
                fontWeight="bold"
            >
                <VStack as={GridItem} align='start' justify='center'>
                    <Text><T>Save</T></Text>
                </VStack>
                <VStack as={GridItem} align='end' justify='center'>
                    <Text p={{ base: 0, md: 1 }} rounded='5' as='span' fontSize="2xl" >
                        {savings}
                    </Text>
                </VStack>
                <VStack as={GridItem} align='start' justify='center' color='#00b3be' order={{ base: 1, md: 0 }}>
                    <Link href='#faq'>
                        <Button variant='link' color='#00b3be' rightIcon={<FaCaretRight />}>
                            <T>know more</T>
                        </Button>
                    </Link>
                </VStack>
                <HStack as={GridItem} align='center' justify='end' >
                    <Text as='span' fontWeight='black' ><T>every</T>{' '}<T>month</T></Text>
                </HStack>

            </SimpleGrid>
            <SliderEntry
                title="_lp:simulator:sales_people:title"
                value={Math.log10(numSales)}
                setValue={v => {
                    setShowCta(true)
                    let vAdjusted = Math.pow(10, v)
                    if (vAdjusted > 100) {
                        vAdjusted = Math.round(vAdjusted / 10) * 10
                    } else if (v > 50) {
                        vAdjusted = Math.round(vAdjusted / 5) * 5
                    }
                    setNumSales(vAdjusted.toFixed(0))
                }}
                min={0}
                max={Math.log10(10000)}
                step={0.01}
                transform={v => {
                    let vAdjusted = Math.pow(10, v)
                    if (vAdjusted > 100) {
                        vAdjusted = Math.round(vAdjusted / 10) * 10
                    } else if (vAdjusted > 50) {
                        vAdjusted = Math.round(vAdjusted / 5) * 5
                    }
                    return `${vAdjusted.toFixed(0)} ${translate('people', locale)}`
                }}
                name="revenue" />

            <SliderEntry
                title="_lp:simulator:hourly_salary"
                value={hourlySalary}
                setValue={v => {
                    setShowCta(true)
                    setHourlySalary(v)
                }}
                min={15}
                max={200}
                step={1}
                transform={value => formatCurrency(value, locale)}
                name="hourly-salary" />

            <SliderEntry
                title="_lp:simulator:hours_per_day:title"
                value={hoursEntering}
                setValue={v => {
                    setShowCta(true)
                    setHoursEntering(v)
                }}
                min={0.25}
                max={5}
                step={0.25}
                transform={value => {
                    const dur = dayjs.duration(value, 'hours');
                    const hours = dur.hours();
                    const minutes = dur.minutes();

                    const formatString = `${hours ? 'H[h]' : ''}${minutes ? 'm[m]' : ''}`;
                    return dur.format(formatString);
                }}
                name="hours-per-day" />
            <Flex justify="center" mt={4}>
                {showCta ? <motion.div
                    initial={{ opacity: 0, scale: 0.3 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3, delay: 2, ease: "linear" }} >
                    <Cta size='xl' bg='black' />
                </motion.div> : <Box visibility='hidden' display={showCta ? 'none' : 'block'}><Cta size='xl' bg='black' /></Box>}
            </Flex>
        </VStack>
    );
};

export default CostSimulator;
